import { createContext, useState, useEffect } from "react";
export const GlobalContext = createContext();
import useFetch from "./hooks/useFetch";
var pColor = "#f6b000";
var sColor = "#080d22";
export function GlobalProvider({ children }) {
  const [isLoading, setisLoading] = useState(true);
  const [primaryColorSet, setprimary] = useState("#f6b000");
  const [secondaryColorSet, setsecondary] = useState("#080d22");
  const [linearGradientColorSet, SetlinearGradientcolor] = useState("#f6b000");
  const [darkColor, setdarkcolor] = useState("#f6b000");
  const [eventYear, seteventYear] = useState("");
  const [eventName, seteventName] = useState("");
  const [eventLocation, seteventLocation] = useState(null);
  const [eventCurrency, seteventCurrency] = useState("Euro");
  const [logoImage, setlogoImage] = useState("./iamges/logos/Logo-header.png");
  const [eventTagline, seteventTagline] = useState(null);
  const [eventDates, seteventDates] = useState(null);
  const [eventColorName, seteventColorName] = useState("");
  const [whiteEventName, setwhiteEventName] = useState("");
  const [isNavOpen, setisNavOpen] = useState(false);
  const [eventCode, seteventCode] = useState(null);
  const [cityShortCOde, setcityShortCOde] = useState("HOU");
  const [eventpostponed, setEventPostponed] = useState(false);
  const [industryname, setIndustryName] = useState(false);
  const [prevAgenda, setPrevAgenda] = useState("false");
  const [RecaptchaKeys, setRecaptchaKeys] = useState("");
  const [StripeMode, setStripeMode] = useState("");
  const [Hubspot, setHubspot] = useState("");
  const [Favicon, setFavicon] = useState("");
  const [Metadescription, setMetadescription] = useState("");
  const [Metakeywords, setMetakeywords] = useState("");
  const [Ogtitle, setOgtitle] = useState("");
  const [Ogdescription, setOgdescription] = useState("");
  const [pattern, setPatterns] = useState("");
  const [hubspotdisposition, setHpdisposition] = useState("");
  const [emailstatus, setEmailstatus] = useState("");
  // const [pattern, setPatterns] = useState(null);
  const [Ogurl, setOgurl] = useState("");
  const [ogicon, setOgicon] = useState("");
  const [style, Setstyle] = useState("");
  const { data: colors } = useFetch("color-theme");
  const { data: eventDetails, loading: loading } = useFetch("event-details");
  const { data: logos } = useFetch("logo-slider");
  const { data: settings } = useFetch("settings");
  const { data: patterns } = useFetch("bg-patterns");
  pColor = primaryColorSet;
  sColor = primaryColorSet;
  const handleNavOpen = (val) => {
    setisNavOpen(val);
  };
  useEffect(() => {
    seteventName(eventColorName + " " + whiteEventName);
  }, [whiteEventName, eventColorName]);

  useEffect(() => {
    Array.isArray(colors) &&
      colors?.map((color, index) => {
        if (color.option === "primary_color") {
          setprimary(color.value);
        }
        if (color.option === "secondary_color") {
          setsecondary(color.value);
        }
        if (color.option === "dark_color") {
          setdarkcolor(color.value);
        }
        if (color.option === "linearGradient_color") {
          SetlinearGradientcolor(color.value);
        }
        if (color.option === "style") {
          Setstyle(color.value);
        }
      });
  }, [colors]);
  useEffect(() => {
    if (patterns && patterns.length > 0) {
      const datapattern = patterns[0];
      setPatterns(datapattern);
    }
  }, [patterns]);
  useEffect(() => {
    Array.isArray(eventDetails) &&
      eventDetails?.map((detail, index) => {
        // console.log("event detaisl", detail);
        if (detail.option === "event_year") {
          seteventYear(detail.value);
        } else if (detail.option === "event_name") {
          setwhiteEventName(detail.value);
        } else if (detail.option === "event_Location") {
          seteventLocation(detail.value);
        } else if (detail.option === "event_currency") {
          seteventCurrency(detail.value);
        } else if (detail.option === "event_tagline") {
          seteventTagline(detail.value);
        } else if (detail.option === "event_dates") {
          seteventDates(detail.value);
        } else if (detail.option === "event_color_name") {
          seteventColorName(detail.value);
        } else if (detail.option === "event_code") {
          seteventCode(detail.value);
        } else if (detail.option === "event_city_shortcode") {
          setcityShortCOde(detail.value);
        } else if (detail.option === "event_postponed") {
          setEventPostponed(detail.value);
        } else if (detail.option === "industry_name") {
          setIndustryName(detail.value);
        } else if (detail.option === "previous_agenda") {
          setPrevAgenda(detail.value);
        } else if (detail.option === "hubspot_disposition") {
          setHpdisposition(detail.value);
        } else if (detail.option === "hubspot_email_status") {
          setEmailstatus(detail.value);
        }
      });
    setisLoading(loading);
  }, [eventDetails]);

  useEffect(() => {
    if (settings && settings.length > 0) {
      const setting = settings[0];
      setRecaptchaKeys(setting.recaptcha_key);
      setStripeMode(setting.stripe_mode);
      setHubspot(setting.hubsport_key);
      setFavicon(setting.favicon);
      setMetadescription(setting.metadescription || "");
      setMetakeywords(setting.metakeywords || "");
      setOgtitle(setting.ogtitle || "");
      setOgdescription(setting.ogdescription || "");
      setOgicon(setting.ogicon || "");
      setOgurl(setting.ogurl || "");
    }
  }, [settings]);
  return (
    <>
      <GlobalContext.Provider
        value={{
          primaryColorSet,
          secondaryColorSet,
          eventDetails,
          eventYear,
          eventName,
          eventColorName,
          eventLocation,
          eventCurrency,
          logoImage,
          eventTagline,
          eventDates,
          logos,
          handleNavOpen,
          isNavOpen,
          whiteEventName,
          isLoading,
          eventCode,
          cityShortCOde,
          eventpostponed,
          industryname,
          prevAgenda,
          linearGradientColorSet,
          darkColor,
          RecaptchaKeys,
          StripeMode,
          Hubspot,
          Favicon,
          Metadescription,
          Metakeywords,
          Ogtitle,
          Ogdescription,
          Ogurl,
          ogicon,
          style,
          pattern,
          hubspotdisposition,
          emailstatus,
        }}
      >
        {children}
      </GlobalContext.Provider>
    </>
  );
}
export { pColor, sColor };
